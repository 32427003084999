import React from "react"

const theme = {
  primaryBlue: "#0e2b89",
  secondaryBlue: "#2b53d6",
  complementBlue: "#2A97E5",
  darkGrey: "#263045",
  grey: "#f7f9fe",
  white: "#fff",
  black: "#000",
  red: "#ff0000",
}

export const myContext = React.createContext(theme)

const Provider = props => {
  return <myContext.Provider value={theme}>{props.children}</myContext.Provider>
}

export default ({ element }) => <Provider>{element}</Provider>
