// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-business-contact-us-js": () => import("./../../../src/pages/business/ContactUs.js" /* webpackChunkName: "component---src-pages-business-contact-us-js" */),
  "component---src-pages-business-finance-only-js": () => import("./../../../src/pages/business/FinanceOnly.js" /* webpackChunkName: "component---src-pages-business-finance-only-js" */),
  "component---src-pages-business-gdpr-js": () => import("./../../../src/pages/business/GDPR.js" /* webpackChunkName: "component---src-pages-business-gdpr-js" */),
  "component---src-pages-business-home-js": () => import("./../../../src/pages/business/Home.js" /* webpackChunkName: "component---src-pages-business-home-js" */),
  "component---src-pages-business-leasing-js": () => import("./../../../src/pages/business/Leasing.js" /* webpackChunkName: "component---src-pages-business-leasing-js" */),
  "component---src-pages-business-solutions-audio-visual-js": () => import("./../../../src/pages/business/solutions/AudioVisual.js" /* webpackChunkName: "component---src-pages-business-solutions-audio-visual-js" */),
  "component---src-pages-business-solutions-bespoke-office-furniture-js": () => import("./../../../src/pages/business/solutions/BespokeOfficeFurniture.js" /* webpackChunkName: "component---src-pages-business-solutions-bespoke-office-furniture-js" */),
  "component---src-pages-business-solutions-laptops-js": () => import("./../../../src/pages/business/solutions/Laptops.js" /* webpackChunkName: "component---src-pages-business-solutions-laptops-js" */),
  "component---src-pages-business-solutions-p-cs-and-servers-js": () => import("./../../../src/pages/business/solutions/PCsAndServers.js" /* webpackChunkName: "component---src-pages-business-solutions-p-cs-and-servers-js" */),
  "component---src-pages-business-solutions-presentation-equipment-js": () => import("./../../../src/pages/business/solutions/PresentationEquipment.js" /* webpackChunkName: "component---src-pages-business-solutions-presentation-equipment-js" */),
  "component---src-pages-business-solutions-wireless-networks-js": () => import("./../../../src/pages/business/solutions/WirelessNetworks.js" /* webpackChunkName: "component---src-pages-business-solutions-wireless-networks-js" */),
  "component---src-pages-business-support-js": () => import("./../../../src/pages/business/Support.js" /* webpackChunkName: "component---src-pages-business-support-js" */),
  "component---src-pages-business-terms-and-conditions-js": () => import("./../../../src/pages/business/TermsAndConditions.js" /* webpackChunkName: "component---src-pages-business-terms-and-conditions-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/ContactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-education-benefits-of-leasing-js": () => import("./../../../src/pages/education/BenefitsOfLeasing.js" /* webpackChunkName: "component---src-pages-education-benefits-of-leasing-js" */),
  "component---src-pages-education-bespoke-js": () => import("./../../../src/pages/education/Bespoke.js" /* webpackChunkName: "component---src-pages-education-bespoke-js" */),
  "component---src-pages-education-climate-control-js": () => import("./../../../src/pages/education/ClimateControl.js" /* webpackChunkName: "component---src-pages-education-climate-control-js" */),
  "component---src-pages-education-contact-us-js": () => import("./../../../src/pages/education/ContactUs.js" /* webpackChunkName: "component---src-pages-education-contact-us-js" */),
  "component---src-pages-education-finance-only-js": () => import("./../../../src/pages/education/FinanceOnly.js" /* webpackChunkName: "component---src-pages-education-finance-only-js" */),
  "component---src-pages-education-gdpr-js": () => import("./../../../src/pages/education/GDPR.js" /* webpackChunkName: "component---src-pages-education-gdpr-js" */),
  "component---src-pages-education-home-js": () => import("./../../../src/pages/education/Home.js" /* webpackChunkName: "component---src-pages-education-home-js" */),
  "component---src-pages-education-interactive-audiovisual-js": () => import("./../../../src/pages/education/InteractiveAudiovisual.js" /* webpackChunkName: "component---src-pages-education-interactive-audiovisual-js" */),
  "component---src-pages-education-it-js": () => import("./../../../src/pages/education/IT.js" /* webpackChunkName: "component---src-pages-education-it-js" */),
  "component---src-pages-education-laptops-js": () => import("./../../../src/pages/education/Laptops.js" /* webpackChunkName: "component---src-pages-education-laptops-js" */),
  "component---src-pages-education-networks-js": () => import("./../../../src/pages/education/Networks.js" /* webpackChunkName: "component---src-pages-education-networks-js" */),
  "component---src-pages-education-terms-and-conditions-js": () => import("./../../../src/pages/education/TermsAndConditions.js" /* webpackChunkName: "component---src-pages-education-terms-and-conditions-js" */),
  "component---src-pages-gdpr-js": () => import("./../../../src/pages/GDPR.js" /* webpackChunkName: "component---src-pages-gdpr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/TermsAndConditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

